<template>
  <div class="bg-gray-100">
    <!-- <h1 class="pt-4 text-center">test</h1> -->
    <div class="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
      <div class="max-w-7xl mx-auto">
        <div class="overflow-hidden  rounded-lg">
          <div class=" px-4 py-5 sm:px-6">
            <div class="px-4 py-5 sm:px-6">
              <div
                class="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap"
              >
                <div class="ml-4 mt-2">
                  <span class="text-gray-500">Selected endpoint:</span>
                  <h3
                    v-on:click="copyToClickboard(this.$route.params.endpoint)"
                    class=" cursor-pointer text-3xl leading-6 flex"
                    title="Copy endpoint to clipboard"
                  >
                    <span class="text-gray-900"
                      >{{ this.$route.params.endpoint }}
                    </span>
                    <svg
                      class="flex-shrink-0 h-7 w-7 ml-2 text-gray-400 group-hover:text-gray-500"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z"
                      />
                    </svg>
                  </h3>
                </div>
                <div class="ml-4 mt-2 flex-shrink-0">
                  <router-link
                    :to="{ name: 'Home' }"
                    type="button"
                    class="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    new endpoint
                  </router-link>
                  <form
                    :action="'/shared/' + this.$route.params.endpoint"
                    method="get"
                    target="_blank"
                    class="inline-flex"
                  >
                    <input
                      for="data"
                      id="data"
                      :value="this.events"
                      type="text"
                      hidden
                    />
                    <button
                      class="ml-3  items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Share
                    </button>
                    <!-- :href="'http://localhost:8080/shared/' + this.$route.params.endpoint + '?data=' + JSON.stringify(this.events)" -->
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div class="px-4 py-5 sm:p-6">
            <div class="flex flex-col">
              <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div
                  class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8"
                >
                  <div
                    class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
                  >
                    <table class="min-w-full divide-y divide-gray-200">
                      <thead class="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            width="1%"
                            class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Method
                          </th>
                          <th
                            scope="col"
                            width="30%"
                            class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Path
                          </th>
                          <th
                            scope="col"
                            width=""
                            class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Query
                          </th>
                          <th
                            scope="col"
                            width="5%"
                            class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Request time
                          </th>
                          <th
                            scope="col"
                            width=""
                            class="text-left text-xs font-medium text-gray-500 uppercase relative px-6 py-3"
                          >
                            <span>edit</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody class="bg-white divide-y divide-gray-200">
                        <transition-group name="fade">
                          <tr
                            v-for="req in $filters.reverseArray(events)"
                            v-bind:key="req"
                          >
                            <td class="px-6 py-4 whitespace-nowrap">
                              <div
                                v-if="req.data && req.data.method"
                                class="flex items-center"
                              >
                                <div class="text-sm font-medium text-gray-900">
                                  {{ req.data.method }}
                                </div>
                              </div>
                              <div v-else class="text-sm text-gray-500">
                                no data
                              </div>
                            </td>
                            <td class="px-6 py-4 whitespace-nowrap">
                              <div
                                v-if="
                                  req.data && req.data.path && req.data.endpoint
                                "
                                class="text-sm text-gray-500"
                              >
                                /{{
                                  $filters.removeEndpointFromPath(
                                    req.data.path,
                                    req.data.endpoint
                                  )
                                }}
                              </div>
                              <div v-else class="text-sm text-gray-500">
                                no data
                              </div>
                            </td>
                            <td class="px-6 py-4 whitespace-nowrap">
                              <code
                                v-if="req.data && req.data.request_body"
                                class="text-sm text-gray-500"
                              >
                                {{ req.data.request_body }}
                              </code>
                              <div
                                v-else-if="req.data && req.data.query"
                                class="text-sm text-gray-500"
                              >
                                {{ $filters.jsonQueryToString(req.data.query) }}
                              </div>
                              <div v-else class="text-sm text-gray-500">
                                no data
                              </div>
                            </td>
                            <td
                              class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                            >
                              <div v-if="req.requestTime">
                                {{ $filters.unixToDate(req.requestTime) }}
                              </div>
                              <div v-else class="text-sm text-gray-500">
                                no data
                              </div>
                            </td>
                            <td
                              class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium"
                            >
                              <a
                                href="#"
                                class="text-indigo-600 hover:text-indigo-900"
                                >Edit</a
                              >
                            </td>
                          </tr>
                        </transition-group>

                        <!-- More people... -->
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line
import { db } from "@/firebase";

export default {
  data() {
    return {
      endpoint: this.$route.params.endpoint,
      collectionPath: "endpoints/" + this.$route.params.endpoint + "/data",
      events: [],
      firestore: [],
    };
  },

  created() {
    // eslint-disable

    // retrieve a collection endpoints
    db.collection(this.collectionPath)
      .get()
      .then((querySnapshot) => {
        const documents = querySnapshot.docs.map((doc) => doc.data());
        this.events = this.$filters.reverseArray(documents);
      });
  },

  mounted() {
    console.log("subscribing to `endpoint-" + this.endpoint + "`...", {
      $pusher: this.$pusher,
    });

    const channel = this.$pusher.subscribe("endpoint-" + this.endpoint);

    channel.bind("pusher:subscription_succeeded", () =>
      console.log("subscription succeeded")
    );

    channel.bind("my-event", (event) => {
      console.log("my-event", event);
      this.events.push(event);

      // Populate db
      db.collection(this.collectionPath)
        .doc()
        .set(event)
        .then(() => {
          console.log("event updated!");
        });
    });
  },
  unmounted() {
    console.log("unsubscribing...");
    console.log(this.$pusher.unsubscribe("endpoint-" + this.endpoint));
  },
  methods: {
    copyToClickboard(endpoint) {
      this.url = "https://req-catch.webits.dev/" + endpoint;

      navigator.clipboard
        .writeText(this.url)
        .then(() => {
          console.log("Text copied to clipboard");
        })
        .catch((err) => {
          // This can happen if the user denies clipboard permissions:
          console.error("Could not copy text: ", err);
        });
    },
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
