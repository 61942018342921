<template>
<div>
  <h2>Unparsed</h2>
  <pre>{{this.events}}</pre>
  
  <h2>Parsed</h2>
  <pre>{{((this.events))}}</pre>


</div>
</template>

<script>
export default {
  data() {
    return {
      events: null
    }
  },

  mounted() {
    this.events = this.$route
  },
}
</script>

<style>

</style>